import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query ServicePageBySlug(
        $locale: String!
        $slug: String!
        $sideMenuId: String!
        $preview: Boolean
    ) {
        sideMenu(id: $sideMenuId, locale: $locale, preview: $preview) {
            ...servicePageEntry
            navigationsCollection(limit: 2) {
                items {
                    ...servicePageNavigation
                }
            }
            storeInfo {
                ...servicePageStore
            }
        }
        pageCollection(
            locale: $locale
            where: { slug: $slug }
            preview: $preview
            limit: 1
        ) {
            items {
                ...servicePage
            }
        }
    }
`);
