import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment slugEntry on Page {
        sys {
            id
        }
        __typename
    }
`);

export default graphql(/* GraphQL */ `
    query Slugs($locale: String!, $preview: Boolean) {
        pages: pageCollection(
            locale: $locale
            where: { type_not_in: ["Wishlist"], type_exists: true }
            limit: 3000
            preview: $preview
        ) {
            items {
                sys {
                    id
                }
                __typename
                slug
                type
                title
            }
        }
        articles: editorialPageCollection(
            locale: $locale
            limit: 3000
            preview: $preview
        ) {
            items {
                sys {
                    id
                }
                __typename
                slug
                title
            }
        }
        campaigns: campaignCollection(
            locale: $locale
            limit: 300
            preview: $preview
        ) {
            items {
                sys {
                    id
                }
                __typename
                slug
                title: campaignTitle
            }
        }
        landingPages: landingPageCollection(
            locale: $locale
            limit: 300
            preview: $preview
        ) {
            items {
                sys {
                    id
                }
                __typename
                slug
                title
            }
        }
    }
`);
