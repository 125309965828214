import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment accountPageEntry on Entry {
        sys {
            id
        }
        __typename
    }
    fragment accountPage on Page {
        ...accountPageEntry
        title
        type
        slug
        ingress {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
        desktopImage {
            ...contentfulAsset
        }
        mobileImage {
            ...contentfulAsset
        }
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        componentsCollection(limit: 10) {
            total
            items {
                ...accountPageCustomerCommunicationPreferences
                ...accountPageMyPageBlock
                ...accountPagePreferences
                ...accountPageNavigation
            }
        }
        dictionary {
            name
            listCollection(limit: 200) {
                items {
                    key
                    name
                    value
                }
            }
        }
        metaTitle
        metaDescription
    }
    fragment accountPageCustomerCommunicationPreferences on CustomerCommunicationPreferences {
        ...accountPageEntry
        name
        consentInfo {
            json
        }
    }
    fragment accountPageMyPageBlock on MyPageBlock {
        ...accountPageEntry
        type
        title
        description {
            json
        }
        success {
            json
        }
        error {
            json
        }
    }
    fragment accountPageNavigation on Navigation {
        ...accountPageEntry
        name
        title
        sublinksCollection(limit: 8) {
            items {
                ...accountPageNavigationLink
            }
        }
    }
    fragment accountPageNavigationLink on NavigationLink {
        ...accountPageEntry
        name
        type
        title
        display
        variant
        externalLink
        internalLink {
            ... on Page {
                ...accountPageEntry
                slug
                shortDescription
            }
            ... on Campaign {
                ...accountPageEntry
                slug
            }
            ... on LandingPage {
                ...accountPageEntry
                slug
            }
        }
    }
    fragment accountPagePreferences on CustomerCommunicationPreferences {
        ...accountPageEntry
        name
        consentInfo {
            json
        }
    }
`);

export default graphql(/* GraphQL */ `
    query AccountPage($locale: String!, $id: String!, $preview: Boolean) {
        page(id: $id, locale: $locale, preview: $preview) {
            ...accountPage
        }
    }
`);
