import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query FormPageBySlug(
        $locale: String!
        $slug: String!
        $limit: Int!
        $preview: Boolean
    ) {
        pageCollection(
            locale: $locale
            where: { slug: $slug }
            limit: $limit
            preview: $preview
        ) {
            items {
                type
                slug
                title
                image
                subtitle
                metaTitle
                metaDescription
                description {
                    json
                }
                dictionary {
                    listCollection(limit: 100) {
                        items {
                            name
                            key
                            value
                        }
                    }
                }
            }
        }
    }
`);
