import { parseDataFromError } from '@server/utils/parseDataFromError';
import { trimGraphqlQuery } from '@server/utils/trimGraphqlQuery';
import type { DocumentNode } from 'graphql';

import { envConfig } from '@/config/env';
import { createServiceAuthToken } from '@/utils/api/createServiceAuthToken';

type GraphQLVariables = Record<string, unknown>;
type GraphQLResponse<T> = { data: T; errors?: any[] };

type RequestOptions = {
    endpoint?: string;
    headers?: HeadersInit;
    next?: NextFetchRequestConfig;
    preview?: boolean;
};

type Request<V> = {
    query: DocumentNode;
    variables?: V;
    options?: RequestOptions;
};

export default async function makeRequest<
    T,
    V extends GraphQLVariables = GraphQLVariables,
>({ query: query, variables, options }: Request<V>): Promise<T> {
    if (!options?.endpoint) {
        throw new Error('Missing endpoint in makeRequest');
    }

    // Disable next cache and revalidation when preview mode is enabled
    const next = options.preview
        ? {
              revalidate: 0,
          }
        : options.next;

    const response: GraphQLResponse<T> = await fetch(options.endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': options.preview
                ? 'no-cache, no-store, must-revalidate'
                : 's-maxage=60, stale-while-revalidate=86400',
            ...options.headers,
        },
        body: JSON.stringify({
            query: trimGraphqlQuery(query),
            variables,
        }),
        next,
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error('makeRequest error', error);

            return parseDataFromError(error);
        });

    if (!response?.data) {
        throw new Error(
            response.errors?.map((error) => error.message).join(', '),
        );
    }

    return response.data;
}

class GraphClient {
    /* eslint-disable-next-line class-methods-use-this */
    request = async <T, V extends GraphQLVariables>(
        args: Request<V>,
    ): Promise<T> => {
        return await makeRequest<T, V>(args);
    };

    contentful = async <T, V extends GraphQLVariables>(
        args: Request<V>,
    ): Promise<T> => {
        if (!envConfig.CONTENTFUL_SPACE_ID) {
            throw new Error('Missing Contentful space ID');
        }

        if (!envConfig.CONTENTFUL_SPACE_ENVIRONMENT) {
            throw new Error('Missing Contentful space environment');
        }

        if (!process.env.CONTENTFUL_SPACE_TOKEN) {
            throw new Error('Missing Contentful space token');
        }

        return this.request<T, V>({
            ...args,
            options: {
                ...args.options,
                endpoint: `https://graphql.contentful.com/content/v1/spaces/${envConfig.CONTENTFUL_SPACE_ID}/environments/${envConfig.CONTENTFUL_SPACE_ENVIRONMENT}`,
                headers: {
                    ...args.options?.headers,
                    Authorization: `Bearer ${args.options?.preview ? process.env.CONTENTFUL_SPACE_PREVIEW_TOKEN : process.env.CONTENTFUL_SPACE_TOKEN}`,
                },
            },
        });
    };

    service = async <T, V extends GraphQLVariables>(
        args: Request<V>,
    ): Promise<T> => {
        if (!envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT) {
            throw new Error('Missing service endpoint');
        }

        if (!envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT_PATH) {
            throw new Error('Missing service endpoint path');
        }

        if (!envConfig.NEXT_PUBLIC_SERVICE_USER) {
            throw new Error('Missing service user');
        }

        if (!process.env.NEXT_PUBLIC_SERVICE_SECRET) {
            throw new Error('Missing service secret');
        }

        return this.request<T, V>({
            ...args,
            options: {
                ...args.options,
                endpoint: `${envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT}/${envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT_PATH}`,
                headers: {
                    ...args.options?.headers,
                    Authorization: createServiceAuthToken(),
                    'x-api-brand': 'noga',
                    ...(args.options?.preview && {
                        'x-api-contentful': 'preview',
                    }),
                },
            },
        });
    };
}

export const client = new GraphClient();

export const contentful = client.contentful.bind(client);
export const service = client.service.bind(client);
export const request = client.request.bind(client);

export * from './queries/contentful';
