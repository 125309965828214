import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment categoryPageProductList on ProductList {
        sys {
            id
        }
        __typename
        count
        heading
        linkText
        commerceData
        link {
            ... on Page {
                slug
            }
        }
    }
    fragment baseCategoryLink on NavigationLink {
        __typename
        sys {
            id
        }
        title
        type
        sublinksCollection(limit: 12) {
            total
            items {
                __typename
                sys {
                    id
                }
                title
                internalLink {
                    ... on Page {
                        __typename
                        sys {
                            id
                        }
                        commerceId
                        slug
                    }
                    ... on EditorialPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                    }
                    ... on LandingPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                    }
                    ... on Campaign {
                        __typename
                        sys {
                            id
                        }
                        slug
                        commerceId
                    }
                }
            }
        }
        internalLink {
            ... on Page {
                __typename
                sys {
                    id
                }
                commerceId
                slug
            }
            ... on EditorialPage {
                __typename
                sys {
                    id
                }
                slug
            }
            ... on LandingPage {
                __typename
                sys {
                    id
                }
                slug
            }
            ... on Campaign {
                __typename
                sys {
                    id
                }
                slug
                commerceId
            }
        }
    }
    fragment categoryPageLink on NavigationLink {
        __typename
        sys {
            id
        }
        type
        title
        internalLink {
            ... on EditorialPage {
                __typename
                sys {
                    id
                }
                slug
                title
            }
            ... on LandingPage {
                __typename
                sys {
                    id
                }
                slug
                title
            }
            ... on Page {
                __typename
                sys {
                    id
                }
                slug
                title
                commerceData
                commerceId
                commerceName
            }
            ... on Campaign {
                __typename
                sys {
                    id
                }
                slug
                title: campaignTitle
            }
        }
        sublinksCollection(limit: 12) {
            items {
                __typename
                sys {
                    id
                }
                type
                title
                internalLink {
                    ... on EditorialPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title
                    }
                    ... on LandingPage {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title
                    }
                    ... on Page {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title
                        commerceData
                        commerceId
                        commerceName
                    }
                    ... on Campaign {
                        __typename
                        sys {
                            id
                        }
                        slug
                        title: campaignTitle
                    }
                }
            }
        }
    }
    fragment categoryPageLinks on PageLinkingCollections {
        navigationLinkCollection(limit: 3) {
            items {
                ...baseCategoryLink
                linkedFrom(allowedLocales: "sv-SE") {
                    navigationLinkCollection(limit: 3) {
                        items {
                            ...baseCategoryLink
                            linkedFrom(allowedLocales: "sv-SE") {
                                navigationLinkCollection(limit: 3) {
                                    items {
                                        ...baseCategoryLink
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    fragment categoryPage on Page {
        __typename
        sys {
            id
        }
        slug
        type
        title
        subtitle
        metaTitle
        metaDescription
        commerceId
        commerceData
        commerceType
        commerceName
        sortProductsBy
        ingress {
            json
        }
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        desktopImage {
            ...contentfulAsset
        }
        mobileImage {
            ...contentfulAsset
        }
        alternativeTermsPage {
            slug
        }
        shortDescription
        sys {
            id
        }
        type
        title
        componentsCollection(limit: 2) {
            items {
                ...categoryPageProductList
            }
        }
        linkedFrom(allowedLocales: "sv-SE") {
            ...categoryPageLinks
        }
        filtersCollection(limit: 25) {
            items {
                ... on Parametric {
                    title
                    attributeName
                    type
                    openByDefault
                    minLabel
                    maxLabel
                    suffix
                    prefix
                }
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query CategoryPage($locale: String!, $id: String!, $preview: Boolean) {
        page(locale: $locale, id: $id, preview: $preview) {
            ...categoryPage
        }
    }
`);
