import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment layoutEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment layoutBrandBanner on BrandBanner {
        ...layoutEntry
        bannerItemsCollection(limit: 30) {
            items {
                ...contentfulImage
            }
        }
    }

    fragment layoutStore on Store {
        ...layoutEntry
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        type
        infoText
        phoneNumber
        email
        address
        city
        postalCode
        googleMapsUrl
        storePage {
            ... on Page {
                slug
            }
        }
        storePageLinkText
        openingHoursCollection(limit: 7) {
            items {
                name
                openingTime
                closingTime
                dayOfTheWeek
                closed
            }
        }
        exceptionalOpeningHoursCollection(limit: 5) {
            items {
                name
                date
                title
                closed
                closingTime
                openingTime
            }
        }
    }
    fragment layoutFooterTextBlock on TextBlock {
        ...layoutEntry
        name
        type
        title
        titleIcon
        content {
            json
        }
        linkText
        pageLink: link {
            slug
        }
    }
    fragment layoutFooterNavigation on Navigation {
        ...layoutEntry
        name
        title
        sublinksCollection(limit: 8) {
            items {
                ...layoutEntry
                title
                internalLink {
                    ... on Page {
                        slug
                    }
                    ... on EditorialPage {
                        slug
                    }
                    ... on LandingPage {
                        slug
                    }
                    ... on Campaign {
                        slug
                    }
                }
            }
        }
    }
    fragment layoutFooter on Footer {
        ...layoutEntry
        name
        banner {
            ...layoutBrandBanner
        }
        columnsCollection(limit: 6) {
            items {
                ...layoutFooterTextBlock
                ...layoutFooterNavigation
                ...layoutStore
            }
        }
    }

    fragment layoutHeader on Header {
        ...layoutEntry
        mobileLogo {
            ...contentfulAsset
        }
        desktopLogo {
            ...contentfulAsset
        }
    }

    fragment layoutSiteinfo on Siteinfo {
        ...layoutEntry
        title
        description
        openGraphImage {
            url
        }
        faviconCollection(limit: 1) {
            total
            items {
                url
            }
        }
        copyright {
            json
        }
        newsletter {
            ...layoutEntry
            name
            reference
            dictionaryCollection(limit: 100) {
                items {
                    ... on KeyValue {
                        key
                        name
                        value
                    }
                }
            }
        }
        newsletterFooter {
            json
            links {
                entries {
                    hyperlink {
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                    }
                }
            }
        }
        globalDictionary {
            listCollection(limit: 200) {
                items {
                    name
                    key
                    value
                }
            }
        }
        header {
            ...layoutHeader
        }
        footer {
            ...layoutFooter
        }
        paymentImagesCollection(limit: 20) {
            items {
                __typename
                ...contentfulImage
            }
        }
        myPagesStartPage {
            ...layoutEntry
            slug
        }
        myPagesRegisterPage {
            ...layoutEntry
            slug
        }
        sideMenuMyPages {
            sys {
                id
            }
        }
        sideMenuServicePages {
            sys {
                id
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query Layout($locale: String!, $dictionary: String!, $preview: Boolean) {
        dictionary(id: $dictionary, locale: $locale, preview: $preview) {
            listCollection(limit: 100) {
                items {
                    key
                    name
                    value
                }
            }
        }
        siteinfoCollection(limit: 1, locale: $locale) {
            items {
                ...layoutSiteinfo
            }
        }
    }
`);
