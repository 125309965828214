import { graphql } from '@server/gql';

export const CampaignFragment = graphql(/* GraphQL */ `
    fragment campaignPageSiteinfo on Siteinfo {
        header {
            navigation {
                __typename
                sys {
                    id
                }
                sublinksCollection(limit: 12) {
                    items {
                        __typename
                        sys {
                            id
                        }
                        type
                        title
                        internalLink {
                            ... on EditorialPage {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                            }
                            ... on LandingPage {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                            }
                            ... on Page {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                                commerceData
                                commerceId
                                commerceName
                            }
                        }
                    }
                }
            }
        }
    }
`);
export default graphql(/* GraphQL */ `
    query CampaignPageBySlug(
        $locale: String!
        $slug: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        siteinfoCollection(locale: $locale, limit: 1, preview: $preview) {
            items {
                ...campaignPageSiteinfo
            }
        }
        campaignCollection(
            locale: $locale
            where: { slug: $slug, activeMarkets_contains_some: $activeMarkets }
            limit: 1
            preview: $preview
        ) {
            items {
                ...campaign
            }
        }
    }
`);
