import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment listPageEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment listPageLink on Page {
        slug
    }

    fragment listPage on Page {
        ...listPageEntry
        type
        title
        metaTitle
        metaDescription
        ingress {
            json
        }
        listType
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ...listPageLink
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        shortDescription
    }
`);

export default graphql(/* GraphQL */ `
    query ListPage($locale: String!, $slug: String!, $preview: Boolean) {
        pageCollection(
            locale: $locale
            limit: 1
            where: { slug: $slug }
            preview: $preview
        ) {
            items {
                ...listPage
            }
        }
    }
`);
