import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query CategoryPageBySlug(
        $locale: String!
        $slug: String!
        $preview: Boolean
        $dictionary: String!
        $limit: Int!
    ) {
        pageCollection(
            where: { slug: $slug }
            locale: $locale
            preview: $preview
            limit: $limit
        ) {
            items {
                ...categoryPage
            }
        }

        dictionary(id: $dictionary, locale: $locale, preview: $preview) {
            listCollection(limit: 100) {
                items {
                    key
                    name
                    value
                }
            }
        }
    }
`);
