import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query PageCommerceIdBySlug(
        $slug: String!
        $locale: String!
        $preview: Boolean
    ) {
        pageCollection(
            locale: $locale
            where: { slug: $slug }
            preview: $preview
        ) {
            items {
                sys {
                    id
                }
                commerceId
            }
        }
    }
`);
