export async function parseDataFromError<T>(
    response: Response,
): Promise<T | null | string> {
    if (!response.body) {
        return Promise.reject(new Error('Unable to retrieve data'));
    }

    const contentType = response.headers.get('Content-Type');

    if (contentType?.includes('application/json')) {
        const rawData = await response.text();

        return JSON.parse(rawData);
    }

    return response.text();
}
