import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment accountLayoutEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment accountLayoutSiteinfo on Siteinfo {
        ...accountLayoutEntry
        globalDictionary {
            listCollection(limit: 500) {
                items {
                    ...accountLayoutEntry
                    key
                    value
                }
            }
        }
        sideMenuMyPages {
            ...accountLayoutSideMenu
        }
        myPagesStartPage {
            ...accountLayoutEntry
            slug
        }
        myPagesRegisterPage {
            ...accountLayoutEntry
            slug
        }
    }
    fragment accountLayoutSideMenu on SideMenu {
        ...accountLayoutEntry
        name
        navigationsCollection(limit: 2) {
            items {
                ...accountLayoutNavigation
            }
        }
    }
    fragment accountLayoutNavigation on Navigation {
        ...accountLayoutEntry
        name
        title
        sublinksCollection(limit: 12) {
            items {
                ...accountLayoutEntry
                type
                title
                display
                variant
                title
                internalLink {
                    ... on Page {
                        slug
                        title
                        commerceData
                        commerceId
                        commerceName
                    }
                    ... on EditorialPage {
                        slug
                        title
                    }
                    ... on Campaign {
                        slug
                        title: campaignTitle
                    }
                    ... on LandingPage {
                        slug
                        title
                    }
                }
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query AccountLayout($locale: String!, $preview: Boolean) {
        siteinfoCollection(limit: 1, locale: $locale, preview: $preview) {
            items {
                ...accountLayoutSiteinfo
            }
        }
    }
`);
