import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment mainNavigationEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment baseNavigationLink on NavigationLink {
        ...mainNavigationEntry
        type
        title
        display
        variant
        specialLinksTitle
        internalLink {
            ... on EditorialPage {
                ...mainNavigationEntry
                slug
                title
            }
            ... on LandingPage {
                ...mainNavigationEntry
                slug
                title
            }
            ... on Page {
                ...mainNavigationEntry
                slug
                title
                commerceData
                commerceId
                commerceName
            }
            ... on Campaign {
                ...mainNavigationEntry
                slug
                title: campaignTitle
            }
        }
        openInNewTab
    }
    fragment navigationSplash on Splash {
        ...mainNavigationEntry
        name
        mobileImage {
            ...contentfulAsset
        }
        desktopImage {
            ...contentfulAsset
        }
        href {
            ... on EditorialPage {
                ...mainNavigationEntry
                slug
                title
            }
            ... on LandingPage {
                ...mainNavigationEntry
                slug
                title
            }
            ... on Page {
                ...mainNavigationEntry
                slug
                title
                commerceData
                commerceId
                commerceName
            }
            ... on Campaign {
                ...mainNavigationEntry
                slug
                title: campaignTitle
            }
            ... on NavigationLink {
                ...baseNavigationLink
            }
        }
        type
        header
        linkText
        textColor
        subheading
    }

    fragment mainNavigationLink on NavigationLink {
        ...baseNavigationLink
        specialLinksCollection(limit: 20) {
            items {
                ...baseNavigationLink
            }
        }
        rightSectionCollection(limit: 6) {
            items {
                ...navigationSplash
            }
        }
        sublinksCollection(limit: 12) {
            items {
                ...baseNavigationLink
                sublinksCollection(limit: 32) {
                    items {
                        ...baseNavigationLink
                    }
                }
            }
        }
    }
    fragment mainNavigation on Navigation {
        ...mainNavigationEntry
        name
        sublinksCollection(limit: 12) {
            items {
                ...mainNavigationLink
            }
        }
    }
    fragment contactNavigation on Navigation {
        ...mainNavigationEntry
        name
        sublinksCollection(limit: 12) {
            items {
                ...baseNavigationLink
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query MainNavigation($locale: String!, $preview: Boolean) {
        headerCollection(locale: $locale, limit: 1, preview: $preview) {
            items {
                navigation {
                    ...mainNavigation
                }
                contactNavigation {
                    ...contactNavigation
                }
            }
        }
    }
`);
