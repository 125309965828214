import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment formPageProductList on ProductList {
        count
        heading
        linkText
        commerceData
        link {
            ... on Page {
                slug
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query FormPage($locale: String!, $id: String!, $preview: Boolean) {
        page(locale: $locale, id: $id, preview: $preview) {
            type
            slug
            title
            image
            ingress {
                json
            }
            subtitle
            metaTitle
            metaDescription
            description {
                json
            }
            desktopImage {
                ...contentfulAsset
            }
            mobileImage {
                ...contentfulAsset
            }
            dictionary {
                listCollection(limit: 100) {
                    items {
                        name
                        key
                        value
                    }
                }
            }
        }
    }
`);
