import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query GlobalDictionary($locale: String!, $preview: Boolean) {
        dictionary(
            id: "AUW4IT51WnUWLjIu1y2XR"
            locale: $locale
            preview: $preview
        ) {
            listCollection(limit: 200) {
                items {
                    __typename
                    sys {
                        id
                    }
                    name
                    key
                    value
                }
            }
        }
    }
`);
