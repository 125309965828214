import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment magazinePageEntry on Entry {
        __typename
        sys {
            id
        }
    }
    fragment baseArticleTag on Tag {
        ...magazinePageEntry
        key
        value
    }
    fragment baseArticle on EditorialPage {
        ...magazinePageEntry
        slug
        title
        desktopHeroImage {
            ...contentfulAsset
        }
        mobileHeroImage {
            ...contentfulAsset
        }
        heroText {
            json
        }
        publishingDate
        heroImageAltText
        ingress {
            json
        }
        category {
            ... on Page {
                ...magazinePageEntry
                title
                slug
            }
        }
        tagsCollection(limit: 10) {
            items {
                ...baseArticleTag
            }
        }
    }
    fragment magazineNavigation on Navigation {
        ...magazinePageEntry
        name
        sublinksCollection(limit: 12) {
            items {
                ...baseNavigationLink
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query MagazinePage(
        $id: String!
        $locale: String!
        $skip: Int!
        $limit: Int!
        $preview: Boolean
    ) {
        page(locale: $locale, id: $id, preview: $preview) {
            ...magazinePageEntry
            slug
            title
            ingress {
                json
            }
            metaTitle
            metaDescription
            dictionary {
                listCollection {
                    items {
                        key
                        name
                        value
                    }
                }
            }
            componentsCollection(limit: 1) {
                items {
                    ...magazineNavigation
                }
            }
            linkedFrom(allowedLocales: "sv-SE") {
                editorialPageCollection(limit: $limit, skip: $skip) {
                    total
                    items {
                        ...baseArticle
                    }
                }
            }
        }
    }
`);
