import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment checkoutPageEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment checkoutPageTextBlock on TextBlock {
        ...checkoutPageEntry
        name
        type
        title
        titleIcon
        content {
            json
            links {
                entries {
                    hyperlink {
                        ... on Page {
                            ...checkoutPageEntry
                            slug
                        }
                    }
                    block {
                        ...contentfulImage
                    }
                }
            }
        }
    }

    fragment checkoutPageStore on Store {
        ...checkoutPageEntry
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        type
        infoText
        phoneNumber
        email
        address
        city
        postalCode
        googleMapsUrl
        storePage {
            ... on Page {
                slug
            }
        }
        storePageLinkText
        openingHoursCollection(limit: 7) {
            items {
                name
                openingTime
                closingTime
                dayOfTheWeek
                closed
            }
        }
        exceptionalOpeningHoursCollection(limit: 5) {
            items {
                name
                date
                title
                closed
                closingTime
                openingTime
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query CheckoutPage(
        $locale: String!
        $slug: String!
        $nshift: String!
        $checkout: String!
        $preview: Boolean
    ) {
        pageCollection(
            limit: 1
            locale: $locale
            where: { slug: $slug }
            preview: $preview
        ) {
            items {
                ...checkoutPageEntry
                type
                metaTitle
                metaDescription
                informationCollection(limit: 10) {
                    items {
                        ...checkoutPageTextBlock
                        ...checkoutPageStore
                    }
                }
            }
        }
        nshift: dictionary(id: $nshift, locale: $locale) {
            listCollection(limit: 100) {
                items {
                    key
                    name
                    value
                }
            }
        }
        checkout: dictionary(id: $checkout, locale: $locale) {
            listCollection(limit: 100) {
                items {
                    key
                    name
                    value
                }
            }
        }
    }
`);
