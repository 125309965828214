import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment registerPageEntry on Entry {
        __typename
        sys {
            id
        }
    }
`);

export default graphql(/* GraphQL */ `
    query RegisterPage($id: String!, $locale: String!, $preview: Boolean) {
        page(locale: $locale, id: $id, preview: $preview) {
            ...registerPageEntry
            title
            ingress {
                json
            }
            dictionary {
                listCollection {
                    items {
                        key
                        name
                        value
                    }
                }
            }
        }
    }
`);
