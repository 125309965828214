import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment brandPageProductList on ProductList {
        __typename
        sys {
            id
        }
        count
        heading
        linkText
        commerceData
        link {
            ... on Page {
                slug
            }
        }
    }
    fragment brandPage on Page {
        __typename
        sys {
            id
        }
        type
        slug
        title
        desktopImage {
            ...contentfulAsset
        }
        mobileImage {
            ...contentfulAsset
        }
        subtitle
        metaTitle
        metaDescription
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        shortDescription
        commerceId
        commerceData
        commerceType
        commerceName
        componentsCollection(limit: 2) {
            items {
                ...brandPageProductList
            }
        }
        filtersCollection(limit: 25) {
            items {
                ... on Parametric {
                    title
                    attributeName
                    type
                    openByDefault
                    minLabel
                    maxLabel
                    suffix
                    prefix
                }
            }
        }
    }
    fragment brandPageSiteinfo on Siteinfo {
        header {
            navigation {
                __typename
                sys {
                    id
                }
                sublinksCollection(limit: 12) {
                    items {
                        __typename
                        sys {
                            id
                        }
                        type
                        title
                        internalLink {
                            ... on EditorialPage {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                            }
                            ... on LandingPage {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                            }
                            ... on Page {
                                __typename
                                sys {
                                    id
                                }
                                slug
                                title
                                commerceData
                                commerceId
                                commerceName
                            }
                        }
                    }
                }
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query BrandPage(
        $locale: String!
        $brand: String!
        $category: String!
        $preview: Boolean
    ) {
        siteinfoCollection(locale: $locale, limit: 1) {
            items {
                ...brandPageSiteinfo
            }
        }
        brand: page(locale: $locale, id: $brand, preview: $preview) {
            ...brandPage
        }
        category: page(locale: $locale, id: $category) {
            ...categoryPage
        }
    }
`);
