import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query EditorialPageBySlug(
        $locale: String
        $slug: String!
        $limit: Int!
        $preview: Boolean
    ) {
        editorialPageCollection(
            locale: $locale
            where: { slug: $slug }
            limit: $limit
            preview: $preview
        ) {
            items {
                ...editorialPageEntry
                slug
                title
                heroImageAltText
                publishingDate
                mobileHeroImage {
                    ...contentfulAsset
                }
                desktopHeroImage {
                    ...contentfulAsset
                }
                metaTitle
                metaDescription
                metaKeywords
                heroText {
                    json
                }
                ingress {
                    json
                }
                category {
                    ... on Page {
                        ...editorialPageCategory
                    }
                }
                tagsCollection(limit: 10) {
                    items {
                        ...editorialPageEntry
                        key
                        value
                    }
                }
                content {
                    json
                    links {
                        entries {
                            block {
                                ...editorialPageEditorialGallery
                                ...editorialPageEditorialSplash
                                ...editorialPageEditorialRow
                                ...editorialPageEditorialProducts
                                ...editorialPageNavigationLink
                            }
                            hyperlink {
                                sys {
                                    id
                                }
                                ... on Page {
                                    slug
                                }
                                ... on EditorialPage {
                                    slug
                                }
                                ... on LandingPage {
                                    slug
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`);
