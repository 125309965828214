import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment wishlistPageEntry on Entry {
        sys {
            id
        }
        __typename
    }
    fragment wishlistPage on Page {
        ...wishlistPageEntry
        title
        metaTitle
        metaDescription
        dictionary {
            listCollection(limit: 100) {
                items {
                    ...wishlistPageEntry
                    key
                    value
                }
            }
        }
        componentsCollection(limit: 1) {
            items {
                ...wishlistPageBlock
            }
        }
    }
    fragment wishlistPageBlock on MyPageBlock {
        ...wishlistPageEntry
        name
        type
        title
        description {
            json
            links {
                entries {
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        success {
            json
        }
        error {
            json
        }
    }
`);

export default graphql(/* GraphQL */ `
    query WishlistPage($locale: String!) {
        pageCollection(
            locale: $locale
            where: { type: "Wishlist", type_exists: true }
            limit: 1
        ) {
            items {
                ...wishlistPage
            }
        }
    }
`);
