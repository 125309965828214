import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment dictionaryEntry on Entry {
        sys {
            id
        }
        __typename
    }
    fragment dictionaryKeyValue on KeyValue {
        ...dictionaryEntry
        key
        name
        value
    }
`);

export default graphql(/* GraphQL */ `
    query Dictionary($id: String!, $locale: String!, $preview: Boolean) {
        dictionary(locale: $locale, id: $id, preview: $preview) {
            ...dictionaryEntry
            listCollection(limit: 250) {
                items {
                    ...dictionaryKeyValue
                }
            }
        }
    }
`);
