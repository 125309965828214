import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment campaignEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment campaignWithImage on Campaign {
        ...campaign
        image {
            ...contentfulAsset
        }
    }
`);

export default graphql(/* GraphQL */ `
    query Campaigns(
        $locale: String!
        $preview: Boolean
        $activeMarkets: [String]
    ) {
        campaignCollection(
            where: { activeMarkets_contains_some: $activeMarkets }
            locale: $locale
            limit: 100
            preview: $preview
        ) {
            items {
                ...campaignWithImage
            }
        }
    }
`);
