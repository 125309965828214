import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query KeyValue($locale: String!, $keys: [String!]!, $preview: Boolean) {
        keyValueCollection(
            locale: $locale
            where: { key_in: $keys }
            preview: $preview
        ) {
            items {
                key
                value
            }
        }
    }
`);
