import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment rootLayoutEntry on Entry {
        __typename
        sys {
            id
        }
    }

    fragment rootLayoutSiteinfo on Siteinfo {
        ...rootLayoutEntry
        title
        description
        faviconCollection(limit: 1) {
            total
            items {
                url
            }
        }
        openGraphImage {
            url
        }
        logoImgixCollection(limit: 2) {
            items {
                ...rootLayoutEntry
                image
                altText
            }
        }
        copyright {
            json
        }
        newsletter {
            ...rootLayoutEntry
            name
            reference
            dictionaryCollection(limit: 100) {
                items {
                    ... on KeyValue {
                        key
                        name
                        value
                    }
                }
            }
            trigger {
                __typename
                sys {
                    id
                }
                label
                variant
            }
        }
        globalDictionary {
            listCollection(limit: 200) {
                items {
                    name
                    key
                    value
                }
            }
        }
        sideMenuServicePages {
            ...rootLayoutEntry
        }
        sideMenuMyPages {
            ...rootLayoutEntry
        }
        myPagesStartPage {
            ...rootLayoutEntry
            slug
        }
        myPagesRegisterPage {
            ...rootLayoutEntry
            slug
        }
    }
`);

export default graphql(/* GraphQL */ `
    query RootLayout(
        $locale: String!
        $country: String!
        $dictionary: String!
        $preview: Boolean
    ) {
        dictionary(id: $dictionary, locale: $locale, preview: $preview) {
            listCollection(limit: 100) {
                items {
                    key
                    name
                    value
                }
            }
        }
        siteinfoCollection(limit: 1, locale: $locale) {
            items {
                ...rootLayoutSiteinfo
            }
        }
        wishlist: pageCollection(
            limit: 1
            locale: $locale
            where: { type: "Wishlist" }
        ) {
            items {
                slug
            }
        }
        campaigns: campaignCollection(
            limit: 100
            locale: $locale
            where: { activeMarkets_contains_some: [$country] }
        ) {
            items {
                campaignTitle
                campaignBadge
                campaignLabel
                commerceId
                slug
                campaignStart
                campaignEnd
                showBadgeForAllProductsInCampaign
            }
        }
    }
`);
