import { graphql } from '@server/gql';

export const fragments = graphql(/* GraphQL */ `
    fragment genericPage on Page {
        __typename
        sys {
            id
        }
        type
        metaTitle
        metaDescription
        shortDescription
        description {
            json
            links {
                entries {
                    block {
                        ...form
                        ...store
                        ...accordion
                        ...editorialRow
                        ...editorialSplash
                        ...linkList
                        ...navigationLink
                        ...highlightRow
                    }
                    hyperlink {
                        __typename
                        sys {
                            id
                        }
                        ... on Page {
                            slug
                        }
                        ... on EditorialPage {
                            slug
                        }
                        ... on LandingPage {
                            slug
                        }
                        ... on Campaign {
                            slug
                        }
                    }
                }
            }
        }
        componentsCollection(limit: 10) {
            total
            items {
                ...form
                ...brandBanner
                ...textBlock
                ...productList
                ...highlightRow
                ...contentRow
            }
        }
        dictionary {
            name
            listCollection(limit: 100) {
                items {
                    key
                    value
                }
            }
        }
    }
`);

export default graphql(/* GraphQL */ `
    query GenericPage($locale: String!, $id: String!, $preview: Boolean) {
        page(locale: $locale, id: $id, preview: $preview) {
            ...genericPage
        }
    }
`);
