import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query PageAttributes(
        $locale: String!
        $filter: PageFilter!
        $limit: Int
        $skip: Int
        $order: [PageOrder]
        $preview: Boolean
    ) {
        pageCollection(
            locale: $locale
            limit: $limit
            where: $filter
            order: $order
            skip: $skip
            preview: $preview
        ) {
            items {
                __typename
                sys {
                    id
                    firstPublishedAt
                }
                slug
                type
                title
                metaTitle
            }
        }
    }
`);
