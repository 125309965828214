import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query RootMagazinePage(
        $id: String!
        $locale: String!
        $skip: Int!
        $limit: Int!
        $tag: String
        $preview: Boolean
    ) {
        page(locale: $locale, id: $id, preview: $preview) {
            ...magazinePageEntry
            slug
            title
            ingress {
                json
            }
            metaTitle
            metaDescription
            dictionary {
                listCollection {
                    items {
                        key
                        name
                        value
                    }
                }
            }
            componentsCollection(limit: 1) {
                items {
                    ...magazineNavigation
                }
            }
        }
        editorialPageCollection(
            limit: $limit
            skip: $skip
            locale: $locale
            where: { tags: { value_contains: $tag } }
        ) {
            total
            items {
                ...baseArticle
            }
        }
    }
`);
