// https://developer.voyado.com/en/web-activity-tracking.html

import type { CartItem } from '@server/gql/graphql';

import { parseJwt } from './parseJwt';

declare global {
    interface Window {
        va: any;
    }
}

export function sendVoyadoEmptyCartEvent(cartId?: string) {
    if (window.va === undefined || !cartId) {
        return;
    }

    window.va('emptyCart', {
        cartRef: cartId,
    });
}

export function sendVoyadoCartChangeEvent(props: {
    contentfulLocale: string;
    cartId?: string;
    cartItems: CartItem[];
}) {
    if (window.va === undefined) {
        return;
    }

    const items = props.cartItems.map((cartItem) => ({
        itemId: cartItem.partNo,
        quantity: cartItem.quantity,
    }));

    window.va('cart', {
        cartRef: props.cartId,
        // cookie _vaI will be used as fallback for contactId
        // contactId: 'afa7625d-2e97-4667-b4c1-ad3b01194bee',
        // cartUrl: 'https://www.store.se/cart?id=354354',
        locale: props.contentfulLocale,
        items,
    });
}

export function setVoyadoContactId(token: string) {
    try {
        const parsedToken = parseJwt(token);
        const voyadoContactId = parsedToken['ng.voyadoid'];

        if (voyadoContactId) {
            window.va('setContactId', voyadoContactId);
        }
    } catch (error) {
        console.error('Failed to set Voyado Contact ID: ', error);
    }
}

interface ProductViewProps {
    breadcrumbLabels: string[];
    itemId: string;
    contentfulLocale: string;
}

export function sendVoyadoProductViewEvent(props: ProductViewProps) {
    try {
        if (window.va === undefined) {
            return;
        }

        const categoryName = props.breadcrumbLabels
            .slice(0, -1)
            .filter((b) => b)
            .join(' / ');
        window.va('productview', {
            categoryName,
            itemId: props.itemId,
            locale: props.contentfulLocale,
        });
    } catch (error) {
        console.error('Failed to send Voyado product view event: ', error);
    }
}

export function sendVoyadoEmailRegistrationEvent(email: string) {
    if (window.va === undefined) {
        return;
    }

    window.va('emailRegistration', {
        email,
    });
}
